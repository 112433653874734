<template>
  <div class="content">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">Ingredient Units <span class="tag is-large is-primary">Total: {{ units.length }}</span></h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button outlined type="is-primary" class="right" icon-left="plus" @click="createNew">Add</b-button>
        </div>
      </div>
    </div>
    <b-table
      :data="units"
      hoverable
      striped
      @click="open" >
      <b-table-column field="term" label="Unit" sortable searchable v-slot="props">
          {{ props.row.term }}
      </b-table-column>
      <b-table-column field="plural" label="Plural" v-slot="props">
          {{ props.row.plural }}
      </b-table-column>
      <b-table-column field="created_at" width=60 label="Created" sortable centered v-slot="props">
          <span class="tag is-primary">
              {{ new Date(props.row.created_at).toLocaleDateString() }}
          </span>
      </b-table-column>
      <b-table-column field="delete" width=40 centered v-slot="props">
        <b-button type="is-danger" size="is-small"  icon-right="delete" @click.stop="deleteItem(props.row)" />
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import db from "@/firebase/db"

export default {
  name: 'IngredientUnitListView',
  data() {
    return {
      units: []
    }
  },
  firestore: {
    units: db.collection('ingredient_units').orderBy("created_at", "desc")
  },
  methods: {
    createNew() {
      db.collection("ingredient_units").add({
          created_at: Date.now(),
          term: null,
          plural: null
      })
      .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.$router.push({ name: 'IngredientUnitView', params: { id: docRef.id } })
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
    },
    open(unit) {
      this.$router.push({ name: 'IngredientUnitView', params: { id: unit.id } })
    },
    deleteItem(unit) {
        console.log(unit.term)
        this.$buefy.dialog.confirm({
            title: 'Deleting ingredient unit',
            message: `Are you sure you want to delete ingredient unit: <b><i>"${unit.term}"</i></b>? <br /><br />This action cannot be undone.`,
            confirmText: 'Delete Ingredient unit',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              db.collection("ingredient_units").doc(unit.id)
              .delete()
              .then(() => {
                this.$buefy.toast.open({type: 'is-success', message: `Ingredient unit successfully deleted!`})
              }).catch((error) => {
                this.$buefy.toast.open({type: 'is-danger', message: `Error deleting ingredient unit: ${error}`})
              });
            }
        })
    }
  }
}

</script>
